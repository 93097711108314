import { Model } from '../abstract/model.abstract'

import { UserPermissionModel } from './user-permission.model'

export class UserModel extends Model {
  id: number
  email: string
  createdAt: string
  updatedAt: string
  statusId: number
  roles: any
  permissions: UserPermissionModel[]

  get isActive(): boolean {
    return this.statusId !== 6
  }

  get role(): string {
    return this.roles.map((el: any) => el.role.name).join(',')
  }
}
