import { Injectable } from '@angular/core';

export interface IAlert {
  id: number;
  text: string;
  type: number;
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  alerts: IAlert[] = [];

  currentId: number = 0;

  addAlert(item: IAlert) {
    this.currentId += 1;
    item.id = this.currentId;
    this.alerts.push(item);
    setTimeout(() => {
      this.removeAlert(item.id);
    }, 10000);
  }

  removeAlert(id: number) {
    this.alerts = this.alerts.filter(itm => itm.id !== id);
  }
}
