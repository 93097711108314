import { TranslocoService } from '@jsverse/transloco'
import dayjs from 'dayjs'
import { jwtDecode } from 'jwt-decode'
import { Observable, Subscription, interval, of } from 'rxjs'
import { tap } from 'rxjs/internal/operators/tap'

import { environment } from '../../../environments/environment'
import { UserModel } from '../models/user.model'

import { AlertService } from './alert.service'
import { TokenService } from './token.service'

import { HttpClient } from '@angular/common/http'
import { EventEmitter, Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'

export interface AuthData {
  token: string
  refreshToken: string
  permissions: string
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly api = `${environment.api.url}/${environment.api.routes.api}/${environment.api.routes.auth}`

  protected http: HttpClient = inject(HttpClient)
  private router: Router = inject(Router)
  private tokenService: TokenService = inject(TokenService)
  private alertService: AlertService = inject(AlertService)
  private translocoService: TranslocoService = inject(TranslocoService)

  currentUser: UserModel | null = null

  refreshInProgress: boolean

  get isSigned(): boolean {
    return !!(this.tokenService.token || false)
  }

  get isTokenExp() {
    const token = this.tokenService.token

    if (!token) {
      return false
    }

    const decodedToken: { exp: number } = jwtDecode(token)

    const expirationTime = decodedToken.exp * 1000

    const now = dayjs()
    const expirationDate = dayjs(new Date(expirationTime))

    const diff = expirationDate.diff(now, 'minutes')

    return diff <= 2
  }

  interval$: Subscription

  userUpdateEvent: EventEmitter<void> = new EventEmitter<void>()

  constructor() {
    this.interval$ = interval(1000).subscribe(() => {
      if (this.isTokenExp) {
        this.refresh()
          .pipe(tap((data: any) => data))
          .subscribe()
      }
    })
  }

  beforeRequest(): Observable<any> {
    if (this.isTokenExp) {
      return this.refresh().pipe(tap((data: any) => data))
    }

    return of({})
  }

  login(params: { email: string; password: string }): Observable<any> {
    return this.http.post(`${this.api}/login?date=${Date.now()}`, params).pipe(
      tap((authData: any) => {
        this.currentUser = new UserModel(authData)
        this.setAuthData(authData)
        this.userUpdateEvent.emit()
        this.router.navigateByUrl('/')
      }),
    )
  }

  auth(): any {
    return this.http.get(`${this.api}/user?date=${Date.now()}`).pipe(
      tap((user: any) => {
        this.currentUser = new UserModel(user)
        this.userUpdateEvent.emit()

        return this.currentUser
      }),
    )
  }

  refresh(): Observable<UserModel | null> {
    if (this.refreshInProgress) {
      return of(null)
    }

    this.refreshInProgress = true

    return this.http
      .post(`${this.api}/refresh?date=${Date.now()}`, {
        refreshToken: this.tokenService.refreshToken,
      })
      .pipe(
        tap({
          next: (user: any) => {
            this.currentUser = new UserModel(user)
            this.userUpdateEvent.emit()
            this.setAuthData(user)
            this.alertService.addAlert({
              id: 0,
              type: 1,
              text: 'Авторизація оновлена!',
            })
            this.refreshInProgress = false

            return this.currentUser
          },
          error: () => {
            this.refreshInProgress = false
            this.logout()
          },
        }),
      )
  }

  logout() {
    this.currentUser = new UserModel({})
    this.clearAuthData()
  }

  setAuthData(authData: AuthData) {
    this.tokenService.setToken(authData.token)
    this.tokenService.setRefreshToken(authData.refreshToken)
  }

  clearAuthData() {
    this.tokenService.clearToken()
    this.tokenService.clearRefreshToken()
    this.alertService.alerts = []
    this.router.navigateByUrl('/auth').then()
  }
}
