import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private localStorageService: LocalStorageService) {}

  get token(): string {
    return this.localStorageService.getItem('token');
  }

  get refreshToken(): string {
    return this.localStorageService.getItem('refreshToken');
  }

  setToken(token: string) {
    document.cookie = `token=${token};domain=.${window.location.host}`;
    return this.localStorageService.setItem('token', token);
  }

  setRefreshToken(token: string) {
    document.cookie = `refreshToken=${token};domain=.${window.location.host}`;
    return this.localStorageService.setItem('refreshToken', token);
  }

  clearToken() {
    return this.localStorageService.removeItem('token');
  }

  clearRefreshToken() {
    return this.localStorageService.removeItem('refreshToken');
  }
}
