export class Model {
  constructor(item: object) {
    if (item) {
      Object.assign(this, item)
    }

    this.onInit()
  }

  static fromJson(item: object): object {
    const model: object = new this(item)

    return model
  }

  static fromJsonArray(data: object[]): object[] {
    const models: object[] = []

    if (data?.length) {
      data.forEach((item) => {
        models.push(this.fromJson(item))
      })
    }

    return models
  }

  getTranslation(translations: any[], defaultLang = 'ua') {
    const defaultTranslation = translations?.find((itm) => itm.locale === defaultLang)

    return defaultTranslation || translations?.[0]
  }

  onInit() {}
}
